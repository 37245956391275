<template>
  <v-col class="page page-newsdetail mt-5">
    <v-col class="headline font-weight-light mb-1">{{ title }}</v-col>
    <v-col class="mb-3">{{ date }}</v-col>
    <div v-html="content"></div>

  </v-col>
</template>

<script>

export default {
  name: 'News',
  data() {
    return {
      title: '',
      content: '',
      introduction: '',
      date: '',
    };
  },
  watch: {
    $route: {
      handler() {
        this.getArticle(this.$route.params.newsId);
      },
      deep: true,
    },
  },
  methods: {
    getArticle(id) {

      this.$http.get('news/' + id).then((response) => {
        this.title = response.data.data.title;
        this.introduction = response.data.data.introduction;
        this.content = response.data.data.content;
        this.date = response.data.data.date;
      });
    },
  },
  created() {
    this.getArticle(this.$route.params.newsId);
  },
};
</script>

<style>
h3 * {
  color: #009cb4;
}
</style>
